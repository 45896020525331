import { cva, type VariantProps } from 'class-variance-authority';
import { forwardRef, type ImgHTMLAttributes } from 'react';

import { useTheme } from '#app/routes/resources+/theme-switch.tsx';
import { cn } from '#app/utils/misc.tsx';

import { default as logoDark } from './logo-icon.svg';
import { default as logoLight } from './logo-icon_white.svg';

const logoVariants = cva('', {
	variants: {
		variant: {
			light: '',
			dark: '',
		},
		themed: {
			true: true,
			false: false,
		},
		size: {
			font: 'w-[1em] h-[1em]',
			xs: 'w-10 h-10',
			sm: 'w-12 h-12',
			md: 'w-16 h-16',
			lg: 'w-24 h-24',
			xl: 'w-32 h-32',
		},
	},
	defaultVariants: {
		size: 'lg',
	},
});

interface LogoProps
	extends ImgHTMLAttributes<HTMLImageElement>,
		VariantProps<typeof logoVariants> {}

export const Logo = forwardRef<HTMLImageElement, LogoProps>(
	({ className, variant, themed, size, ...props }, ref) => {
		const theme = useTheme();

		const src = (v: typeof variant) => {
			if (!v) v = 'light';
			if (themed) return theme === v ? logoLight : logoDark;
			else return v === 'dark' ? logoDark : logoLight;
		};

		return (
			<img
				ref={ref}
				className={cn(logoVariants({ size }), className)}
				src={src(variant)}
				alt="Company Run Logo"
				{...props}
			/>
		);
	},
);
Logo.displayName = 'Logo';
